import React from 'react';
import { Link } from 'gatsby';

import Navbar from '../navbar';

import styles from './header.module.scss';

const Header = () => (
  <header className={styles.header}>
    <Link className={styles.headerLink} to="/">
      <h1 className={styles.siteTitle}>RTFENTON</h1>
    </Link>
    <div className={styles.navLinkContainer}>
      <Navbar />
    </div>
  </header>
);

export default Header;
