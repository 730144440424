import React from 'react';

import SVGIconButton from '../svg-icon-button';

import styles from './social-media-links.module.scss';
import { LinkedIn, Twitter, Instagram } from '../../assets/svg';

const SocialMediaLinks = () => (
  <div className={styles.socialMediaLinks}>
    <SVGIconButton svgIcon={Instagram} to="https://instagram.com/rtfenton" />
    <SVGIconButton svgIcon={Twitter} to="https://twitter.com/rtfenton" />
    <SVGIconButton svgIcon={LinkedIn} to="https://www.linkedin.com/in/rosemary-fenton-05603442" />
  </div>
);

export default SocialMediaLinks;
