import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import classNames from 'classnames';

import styles from './link.module.scss';

const Link = ({ to, children, variant }) => {
  const title = String(children).toUpperCase();

  return (
    <GatsbyLink
      to={to}
      className={classNames(styles.link, variant && styles[variant])}
      activeClassName={styles.active}
    >
      { title }
    </GatsbyLink>
  );
};

export default Link;
