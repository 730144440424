import React from 'react';
import classnames from 'classnames';

import styles from './svg-icon-button.module.scss';

const SVGIconButton = ({
  to, svgIcon: SVGIcon, label, variant,
}) => (
  <a
    role="button"
    className={classnames(
      styles.svgIconButton,
      styles[variant || 'light'],
      !label && styles.iconOnly,
    )}
    href={to}
    target="_blank"
    rel="noreferrer noopener"
  >
    <SVGIcon />
    { label ? <span className={styles.label}>{ label }</span> : null }
  </a>
);

export default SVGIconButton;
