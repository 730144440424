import React from 'react';
import Link from '../link';

import styles from './navbar.module.scss';

const Navbar = () => (
  <nav className={styles.navbar}>
    <Link to="/">portfolio</Link>
    <Link to="/cv">cv</Link>
    <Link to="/about">about</Link>
  </nav>
);

export default Navbar;
