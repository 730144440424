import React from 'react';
import 'normalize.css';

import '../../styles/global.scss';

import SEO from '../../components/seo';
import Header from '../../components/header';
import Footer from '../../components/footer';

import styles from './container.module.scss';

const Container = ({ children }) => (
  <>
    <SEO />
    <div className={styles.contentWrapper}>
      <Header />
      <main className={styles.mainContent}>
        {children}
      </main>
      <Footer />
    </div>
  </>
);

export default Container;
